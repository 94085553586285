import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Input from 'components/base/forms/Input'
import { useAuth } from 'common/providers/useAuth'
import { ButtonText } from '../base/forms/Button'
import useProfile from '../../common/providers/useProfile'
import { Profile } from 'common/state-type'
import Card from '../Card'
import { onLoggedIn } from '../../common/providers/useLoggedInRedirect'
import { ButtonPrimary } from 'components/base/forms/Button'

const schema = yup.object().shape({
  emailAddress: yup
    .string()
    .email('email must be valid')
    .required('email is required'),
  password: yup.string().required('password is required'),
})


const Login: React.FC = ({ type, themeColors }) => {
  const { getProfile } = useProfile()
  const { login, userError, userLoading } = useAuth()

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      emailAddress: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      login(
        {
          username: values.emailAddress,
          password: values.password,
        },
        {
          onSuccess: () => {
            getProfile(
              {},
              {
                onSuccess: onLoggedIn,
              },
            )
          },
        },
      )
    },
  })

  const {
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    setTouched,
    touched,
    values,
    isValid,
  } = formik
  const onSubmit = (e) => {
    Utils.preventDefault(e)
    const newTouched = {}
    Object.keys(errors).map((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  const customHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'emailAddress') {
      formik.setFieldValue(name, value.toLowerCase());
    } else {
      formik.handleChange(event);
    }
  };


  return (
    <div className='col-md-6'>
      <Card>
        <form className='p-4' onSubmit={onSubmit}>
          <h4 className='mb-4'>Log In</h4>
          <Input
            tabIndex={1}
            name='emailAddress'
            onChange={customHandleChange}
            onBlur={handleBlur}
            value={values.emailAddress}
            isValid={touched.emailAddress && !!errors.emailAddress === false}
            style={{ marginBottom: 30 }}
            placeholderChar={'Enter Email'}
            label={'Email'}
          />
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div />
              <ButtonText
                tabIndex={4}
                style={{
                  display: 'flex',
                  marginBottom: -30,
                  zIndex: 10000,
                }}
                onClick={() => API.forgotPasswordRedirect()}
              >
                {'Forgot Password'}
              </ButtonText>
            </div>
            <Input
              tabIndex={2}
              name='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              isValid={touched.password && !!errors.password === false}
              style={{ marginBottom: 30 }}
              type='password'
              placeholderChar={'Enter Password'}
              label={'Password'}
            />
          </div>

          <ErrorMessage data-test='password-validation-error'>
            {touched.password ? errors.password : null}
          </ErrorMessage>
          {/* TODO pass userError as error message once api returns appropriate error messages */}
          {/* Currently, api returns empty message on 401, which is unhelpfully shown with a generic error message on FE */}
          {!!userError && (
            <ErrorMessage>
              {userError === Strings.defaultErrorMessage
                ? 'Unverified user. Please check your inbox to confirm your email'
                : userError}
              {/* {'Unverified user. Please check your inbox to confirm your email'} */}
            </ErrorMessage>
          )}
          <ButtonPrimary
            tabIndex={3}
            data-test='reset-password-btn'
            disabled={!isValid}
            type='submit'
            style={{ marginBottom: 24 }}
          >
            {'Log In'}
          </ButtonPrimary>
          <div>
            <hr
              style={{
                width: '100%',
                textAlign: 'left',
                marginLeft: 0,
                marginTop: 30,
                marginBottom: 30,
              }}
            />
            {
              type === "user" &&
              <>
                <span>{"Don't have an account?"}</span>              
                <ButtonText 
                onClick={() => {
                   const url = themeColors.signupUrl?.startsWith('http') 
                          ? themeColors.signupUrl 
                         : `https://${themeColors.signupUrl}`;
                       themeColors.signupUrl ? window.location.href = url : API.signUpRedirect();
                       }}>
                        {themeColors.signupContent ? themeColors.signupContent : 'Sign Up'}
                   </ButtonText>
              </>
            }
          </div>
        </form>
      </Card>
    </div>
  )
}

export default Login
