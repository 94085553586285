import React, { FunctionComponent } from 'react' // we need this to make JSX compile

type ComponentType = {
  children: React.ReactNode
  error?: boolean
  className?: string
}
import cx from 'classnames'
const Card: FunctionComponent<ComponentType> = ({
  children,
  error,
  className,
}) => {
  return (
    <div className={cx('card', { 'card--error': error }, className)}>
      {children}
    </div>
  )
}

export default Card
