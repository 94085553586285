import React from 'react'
import Login from 'components/auth/Login'
import Card from '../components/Card'
import {useTheme} from '../pages/superadmin/ThemeContext'
import parse from 'html-react-parser';


const LoginPage: React.FC<{}> = () => {
  const { themeColors,themeIcons } = useTheme();
  return (
    <div className='body-light pb-5'>
      <div>
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={themeIcons.loginLogoFileName} alt='login-logo' className='logo mb-5 mt-5' />
          
          <div className='col-md-6'>
            {
              themeColors.loginContent && 
            <Card className='mb-5'>
              <div className='row'>
                <span className='icon fas fa-info-circle text-brand-primary ml-3 mt-1' />
                <div className='col'>
                  <p>
                    {parse(themeColors.loginContent)}
                  </p>
                </div>
              </div>
            </Card>
            }
          </div>
          <Login 
              type="user" themeColors={themeColors} />
          
        </div>
      </div>
      <footer className='sticky-footer bg-white'>
        <div className='container my-auto'>
          <div className='copyright text-cente`r my-auto'></div>
        </div>
      </footer>
    </div>
  )
}

export default LoginPage
